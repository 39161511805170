import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { Avatar } from "@material-ui/core";
import styles from "assets/jss/nextjs-material-kit/pages/landingPageSections/teamStyle.js";
import customStyles from "assets/jss/custom/customStyles.js";

import marieImage from "assets/img/testimonials/sugarbabe-winterthur.jpg";
import robertImage from "assets/img/testimonials/sugardaddy-zuerich.jpg";
import katjaImage from "assets/img/testimonials/sugar-babe-bern-schweiz.jpg";

import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
});

export default function TeamSection() {
  const classes = useStyles();

  const testimonials = [
    {
      name: "Elena K.",
      age: "24",
      location: "Winterthur",
      image: marieImage.src,
      alt: "Sugarbabe Erfahrungen",
      quote:
        "Meine Freundin schwärmte von den aufregenden Dates mit Sugardaddys, die sie auf getasugar kennengelernt hat. Ich wollte dann natürlich auch wissen, wie es ist, erfolgreiche Macher zu daten. Und ich bin begeistert! Mit einem sympathischen, wohlhabenden Geschäftsmann aus Basel reise ich demnächst nach Dubai.",
    },
    {
      name: "Urs K.",
      age: "46",
      location: "Zürich",
      image: robertImage.src,
      alt: "Sugardaddy Erfahrungen",
      quote:
        "Auf getasugar habe ich eine attraktive, junge Frau kennengelernt. Ich liebe es, ihr schönen Seiten des Lebens zu zeigen und gemeinsam außergewöhnliche Momente zu erleben. Gerne stehe ich ihr auch als Mentor zur Seite. Diese besondere Dating Plattform kann ich nur empfehlen, auch wenn ich mich nie als Sugardaddy bezeichnen würde.",
    },
    {
      name: "Anneli B.",
      age: "29",
      location: "Bern",
      image: katjaImage.src,
      alt: "Sugardating Erfahrungen",
      quote:
        "Wer träumt nicht von einem Sugardaddy an seiner Seite? Auf getasugar habe ich ihn gefunden. Schön, dass es noch Gentleman der alten Schule gibt, die wissen wie man eine Frau verwöhnt. Die Restaurant Rechnung und das Wellness Hotel geht natürlich auf ihn. So ist man doch gerne Sugarbabe.",
    },
  ];

  return (
    <GridContainer alignItems="flex-start">
      <GridItem xs={12} className={classes.hasTextCentered}>
        <h2 className={classes.h2}>Warum wir getasugar.ch lieben</h2>
        <p className={classes.isTeaser}>
          Sugardaddys und Sugarbabes aus der Schweiz berichten von ihren Erfahrungen
        </p>
      </GridItem>

      <GridItem xs={12}>
        <Swiper
          slidesPerView="1"
          breakpoints={{
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, A11y]}
          pagination={{ clickable: true }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide>
              <Card
                plain
                style={{ padding: 10, paddingTop: 0, paddingBottom: 0 }}
              >
                <CardHeader image style={{ textAlign: "center" }}>
                  <Avatar
                    src={testimonial.image}
                    alt={testimonial.name}
                    style={{
                      width: "9em",
                      height: "9em",
                      display: "inline-block",
                    }}
                  />
                </CardHeader>
                <CardBody style={{ padding: "0" }}>
                  <h3
                    className={classNames(
                      classes.isBold,
                      classes.hasTextCentered
                    )}
                  >
                    {testimonial.name}
                  </h3>
                  <h6
                    className={classNames(
                      classes.isGrey,
                      classes.hasTextCentered
                    )}
                  >
                    {testimonial.age} Jahre, aus {testimonial.location}
                  </h6>

                  <p className={classes.hasTextCentered}>
                    "{testimonial.quote}"
                  </p>
                </CardBody>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </GridItem>

      <GridItem xs={12} style={{ marginBottom: "1em", marginTop: "2em" }}>
        <p
          className={classNames(
            classes.hasTextCentered,
            classes.isSmall,
            classes.isGrey
          )}
        >
          Eure Privatsphäre ist uns wichtig. Daher findet ihr hier nur beispielshafte Fotos und abgeänderte Namen.
        </p>
      </GridItem>
    </GridContainer>
  );
}
