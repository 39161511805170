import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Icon from "@material-ui/core/Icon";
import LockIcon from "@material-ui/icons/Lock";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

// Icons
import CheckIcon from "assets/img/bestes-sugardaddy-portal.svg"
import SwissIcon from "assets/img/sugardaddy-schweiz.svg"


//styles

import styles from "assets/jss/nextjs-material-kit/components/badgeStyle.js";
import customStyles from "assets/jss/custom/customStyles.js";
import { SvgIcon } from "@material-ui/core";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
});


export default function USPBlock() {
  const classes = useStyles();

  const iconStyle = { fontSize: 60 };
  const iconClasses = classNames(classes.hasTextShadow, classes.hasPadding);

  const lockIcon = (
    <LockIcon
      style={iconStyle}
      className={iconClasses}
    />
  );

  const usps = [
    {
      headline: "SUGARDATING SWITZERLAND",
      subline: "Viele Schweizer Sugardaddy und Sugarbabe Profile",
      icon: <img src={SwissIcon.src} />,
    },
    {
      headline: "DATING AUF NEUEM LEVEL",
      subline: "Die vermutlich beste Website für Sugardating in der Schweiz",
      icon: <img src={CheckIcon.src} style={{width: '100%'}} />,
    },
    {
      headline: "SICHERES SUGARDADDY PORTAL",
      subline: "Serverstandort in Europa und strenger Datenschutz",
      icon: lockIcon,
    },
  ];

  return (
    <div className={classes.rowOdd}>
      <div className={classes.container}>
        <GridContainer
          alignItems="stretch"
          justify="space-between"
          direction="row"
        >
          {usps.map((usp) => (
            <GridItem xs={12} sm={4}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>{usp.icon}</GridItem>
                <GridItem xs={9}>
                  <h6 className={classNames(classes.isOverline)}>
                    {usp.headline}
                  </h6>
                  <h3 className={classNames(classes.isNarrow, classes.isBold)}>
                    {usp.subline}
                  </h3>
                </GridItem>
              </GridContainer>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
