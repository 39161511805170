import React, { useState, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useRouter } from "next/router";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import Head from "next/head";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Hidden from "@material-ui/core/Hidden";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import customStyles from "assets/jss/custom/customStyles.js";

// Sections for this page
import TeamSection from "pages-sections/LandingPage-Sections/TeamSection.js";
//components

import CTABlock from "components/Content/CTABlock";
import USPBlock from "components/Content/USPBlock.js";

//header image

import headerImage1 from "assets/img/header/sugar-daddy-schweiz.jpg";
import mobileHeaderImage1 from "assets/img/header/sugardaddy-schweiz-mobile.jpg";

import sneakSearchProfiles from "assets/img/sugardaddy-website-schweiz.jpg";

import sneakExplore from "assets/img/schweizer-sugardaddy-finden.png";
import sneakMessenger from "assets/img/sugardaddy-chat-ch.png";
import sneakEvents from "assets/img/sugardaddy-treffen-schweiz.png";
import { Grid } from "@material-ui/core";

//Styles

const headerImages = [
	{
		desktop: headerImage1.src,
		mobile: mobileHeaderImage1.src,
		alt: "Sugardaddy Sugarbabe",
	},
];

const headerImage =
	headerImages[Math.floor(Math.random() * Math.floor(headerImages.length))];

//const headerImage = headerImages[2]

const dashboardRoutes = [];

const useStyles = makeStyles({
	...styles,
	...customStyles,
	rowImage: {
		backgroundImage: `url(${headerImage1.src})`,
		backgroundSize: "cover",
		backgroundPosition: "top center",
	},
});

export async function getStaticProps() {
	const tagCities = [
		"Zürich",
		"Genf",
		"Basel",
		"Lausanne",
		"Bern",
		"Winterthur",
		"Luzern",
		"St. Gallen",
		"Lugano",
		"Biel",
	];

	return {
		props: {
			tagCities,
		},
		revalidate: 300,
	};
}

export default function LandingPage(props) {
	const classes = useStyles();
	const router = useRouter();
	const { tagCities, blogTeaser, ...rest } = props;
	const [type, setType] = useState<"0" | "1">("0");

	const purpleCTAContainer = (
		<div className={classNames(classes.rowOdd, classes.isWhite)}>
			<CTABlock />
		</div>
	);

	const headerSignup = (
		<div className={classes.container}>
			<Grid container alignItems="center" justify="space-between">
				<Grid item xs={12} sm={8} md={5} style={{ padding: 15, marginTop: 35 }}>
					<div className={classes.signUpBox}>
						<h1 className={classes.title}>
							Sugardaddys und Sugarbabes aus der Schweiz
						</h1>
						<div>
							<Button
								color="primary"
								fullWidth
								onClick={() => router.push("/register?type=0")}
							>
								ich bin ein Mann
							</Button>
							<Button
								color="primary"
								fullWidth
								onClick={() => router.push("/register?type=1")}
							>
								ich bin eine Frau
							</Button>
						</div>

						<Hidden xsDown>
							<p
								className={classNames(
									classes.hasTextCentered,
									classes.hasMarginTop10
								)}
							>
								<small>
									Bereits bei getasugar?{" "}
									<a
										href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
										className={classNames(classes.isPrimary, classes.isBold)}
									>
										» Login
									</a>
								</small>
							</p>
						</Hidden>
						<div>
							<ul className={classes.checkmarkList}>
								<li>
									<strong>Echte Mitglieder</strong> - Wir prüfen jedes Profil
								</li>
								<li>
									<strong>Flirte anonym</strong> - Keine Echtnamen Pflicht
								</li>
								<li>
									<strong>Immer geschützt</strong> - SSL verschlüsselte Chats
								</li>
							</ul>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={7}></Grid>
			</Grid>
		</div>
	);

	return (
		<div>
			<Header
				color="primary"
				routes={dashboardRoutes}
				rightLinks={<HeaderLinks adaptiveFold={true} />}
				fixed
				{...rest}
			/>
			<Parallax
				responsive
				image={headerImage.desktop}
				mobileImage={headerImage.mobile}
				alt={headerImage.alt}
				style={{ paddingBottom: 20 }}
			>
				<Hidden xsDown>{headerSignup}</Hidden>
			</Parallax>

			<div className={classes.main}>
				<Hidden smUp>{headerSignup}</Hidden>
				<USPBlock />

				<div className={classNames(classes.container, classes.hasMarginTop10)}>
					<Grid container alignItems="flex-start">
						<Grid item xs={12} className={classes.hasTextCentered}>
							<h2 className={classes.h2}>
								Das Sugardaddy Portal für die Schweiz
							</h2>
							<p
								className={classNames(
									classes.isTeaser,
									classes.isTeaserFullwidth
								)}
							>
								Auf getasugar.ch findest du erfolgreiche, schweizer Sugardaddys
								und attraktive Sugarbabes für aufregende Chats und spannende
								Dates.
							</p>

							<img
								src={sneakSearchProfiles.src}
								alt="Sugar Daddy Mobile App"
								className={classes.contentImage}
							/>

							<p>
								Nutze die kostenlose Anmeldung auf unserem Sugardating Portal
								und entdecke zahlreiche Sugardaddys und Sugarbabes aus der
								Schweiz. Auch ohne VIP Mitgliedschaft kannst du ein Profil
								erstellen und andere Mitglieder aufmerksam auf dich machen.
							</p>
							<p>
								Frauen versenden sogar täglich eine Nachricht gratis dank
								unserer Funktion "Catch of the Day". Die Beantwortung von
								Nachrichten ist sowohl für Sugardaddys als auch Sugarbabes
								kostenfrei.
							</p>
						</Grid>
					</Grid>
				</div>
				<div className={classes.rowGrey}>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="center" justifyContent="center">
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classNames(
									classes.hasTextCentered,
									classes.switchOrderMobilePos2
								)}
							>
								<img src={sneakExplore.src} alt="Sugardaddys" height="320" />
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classes.switchOrderMobilePos1}
							>
								<h3>Sugar Profile aus allen Kantonen</h3>
								<p>
									Offen für Dates in deiner Stadt: Erfolgreiche Schweizer
									Sugardaddys aus Zürich, Basel, Luzern oder Bern freuen sich
									auf Kontakt zu attraktiven Damen.
								</p>
							</Grid>
						</Grid>
					</div>
				</div>

				<div>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="center" justifyContent="center">
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classes.switchOrderMobilePos1}
							>
								<h3>Suche gezielt nach Dates</h3>
								<p>
									Nutze unsere Event Funktion, um ein gemeinsames Kennenlernen
									vorzuschlagen und schaue, welcher Sugardaddy oder welches
									Sugarbabe sind bei dir meldet.
								</p>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classNames(
									classes.hasTextCentered,
									classes.switchOrderMobilePos2
								)}
							>
								<img
									src={sneakEvents.src}
									alt="Sugardaddy treffen"
									height="320"
								/>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className={classes.rowGrey}>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="center" justifyContent="center">
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classNames(
									classes.hasTextCentered,
									classes.switchOrderMobilePos2
								)}
							>
								<img
									src={sneakMessenger.src}
									alt="Sugardaddys kennenlernen"
									height="320"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classes.switchOrderMobilePos1}
							>
								<h3>Sicherer Chat bereits integriert</h3>
								<p>
									Lernt euch in unserem Chat besser kennen, tauscht Bilder aus
									und verabredet euch zu einem Treffen. Alles SSL verschlüsselt.
								</p>
							</Grid>
						</Grid>
					</div>
				</div>

				{purpleCTAContainer}

				<div className={classNames(classes.container, classes.hasMarginTop10)}>
					<TeamSection />
				</div>

				{purpleCTAContainer}

				<div className={classNames(classes.container, classes.hasMarginTop10)}>
					<Grid container alignItems="stretch">
						<Grid item xs={12} className={classes.hasTextCentered}>
							<h2 className={classes.h2}>
								Sugardaddys und Sugarbabes aus der Schweiz
							</h2>
							<p
								className={classNames(
									classes.isTeaser,
									classes.isTeaserFullwidth
								)}
							>
								In vielen Schweizer Städten suchen spannende Charaktere nach
								knisternden Dates.
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							className={classNames(
								classes.hasTextCentered,
								classes.hasMarginTop10
							)}
						>
							{tagCities.map((city) => (
								<Chip
									label={city}
									color="primary"
									className={classes.primaryChip}
									onClick={() => router.push("/register")}
								/>
							))}
						</Grid>
					</Grid>
				</div>

				<div className={classes.container}>
					<Grid container alignItems="stretch">
						<Grid item xs={12} className={classes.hasTextCentered}>
							<h2 className={classes.h2}>
								Schweizer Sugar Daddys und Sugar Babes finden
							</h2>

							<p>
								Auf getasugar.ch treffen attraktive Sugar Babes auf erfolgreiche
								Sugardaddys aus der Schweiz. Viele spannende Profile aus Zürich,
								Bern, St. Gallen, Basel und vielen weiteren Schweizer Städten
								freuen sich auf niveauvolle und knisternde Kontakte. Unsere
								Umkreissuche hilft dir, potentielle Sugar Daddys beziehungsweise
								Sugar Babes zu finden. So kommst du einfach und schnell mit
								attraktive Kontakten aus deinem Kanton in Gespräch. Ihr versteht
								euch gut? Dann steht einem persönlichen Kennenlernen Date doch
								nichts mehr im Wege. Möglicherweise ist das dann schon der
								Auftakt in eine prickelnde und aufregende Sugar Beziehung.
							</p>

							<h2 className={classes.h2}>
								Warum getasugar perfekt für Sugardating ist
							</h2>

							<p>
								Wir sind überzeugt, dass du mit getasugar das beste Portal
								gefunden hast, um erfolgreiche Sugardaddys oder attraktive
								Sugarbabes in der Schweiz kennenzulernen. Die Anmeldung
								verpflichtet zu nichts und ist mit keinem Abo verbunden.
								Zahlreiche Mitglieder freuen sich bereits auf deinen Kontakt.
								Viele kostenlose Funktionen machen die Suche nach anderen
								Mitgliedern einfach und komfortabel. Frauen versenden sogar
								einmal täglich eine Flirt Nachricht gratis - möglich macht das
								unser "Catch of the Day". Das Beantworten von Nachrichten ist
								unlimitiert kostenlos möglich.
							</p>

							<p>
								Wir achten auf faires und ehrliches Miteinander. Alle Profile
								werden durch unseren Algorithmus und von getasugar Mitarbeiter
								persönlich geprüft. Damit gewähren wir ein hohes Maß an
								Sicherheit. Viele Mitglieder haben sich zusätzlich freiwillig
								per Ausweis oder Foto Überprüfung verifizieren lassen. So hast
								du eine hohe Sicherheit nicht an Fake User zu geraten. Dennoch
								kannst du getasugar anonym nutzen. Eine Echtnamen Angabe ist
								weder für Sugardaddys noch für Sugarbabys verpflichtend.
							</p>

							<h2 className={classes.h2}>Sugardaddy - Was ist das?</h2>

							<p>
								Als Sugardaddy versteht man einen erfolgreichen Mann, der sich
								gerne spendabel gegenüber seiner meist jüngeren, attraktiven
								Partnerin zeigt. Für beide Partner entsteht so eine Win-Win
								Situation. Er genießt das Zusammensein mit einer hübschen Dame
								und Sie freut sich über regelmäßige Geschenke oder exklusive
								Unternehmungen mit ihrem Sugardaddy. Meist sind Sugardaddy
								Beziehungen unverbindlich angelegt, es gibt aber auch Modelle,
								die klassischen Partnerschaften sehr ähnlich sind. Allen gemein
								ist, dass häufig persönliche Freiheiten des Einzelnen und
								Individualität einen hohen Stellenwert haben.
							</p>

							<h2 className={classes.h2}>
								Sugarbabe werden - Das musst du wissen
							</h2>

							<p>
								Als Sugarbabe bezeichnet man die oftmals jüngere Partnerin oder
								Gespielin eines wohlhabenden oder spandablen Manns, dem
								sogenannten Sugardaddy. Nicht erst seit zahlreicher Bestseller
								Romane zu dem Thema reizt viele Frauen der Gedanke, ein
								Abenteuer mit einem erfolgreichen Geschäftsmann zu erleben und
								in seine Welten einzutauchen. Wenn du als Frau ein Sugarbabe
								werden möchtest, eigenen sich Sugardaddy Portale wie getasugar
								perfekt um einen solchen Mann kennenzulernen. In geschütztem
								Umfeld kannst du hier mit deiner möglichen Affaire oder den
								potentiellen, reichen Partner erste Fragen stellen. Verifizierte
								Profile verschaffen sowohl dir als auch dem Sugardaddy die
								nötige Sicherheit. Wir wünschen euch viel Spaß beim Flirten!
							</p>

							<h2 className={classes.h2}>
								Wo in der Schweiz leben die meisten reichen Männer?
							</h2>

							<p>
								Die meisten Franken Millionäre der Schweiz leben in Zürich.
								Circa 90.000 Einwohner haben hier ein 7-stelliges Vermögen. Das
								sind knapp 10 Prozent der steuerpflichtigen Personen der Stadt.
								Den größten Millionärsanteil findet man dagegen in Zug. Hier
								sind knapp 15 Prozent der steuerpflichtigen Einwohner
								Millionäre. Die Chancen als Frau einen reichen Schweizer
								kennenzulernen stehen also in diesen Städten sehr gut. Mit etwas
								Glück kann man also in den Bars und Restaurants dieser Städte
								auf einen erfolgreichen und gut situierten Flirt treffen.
								Alternativ bieten sich Dating Plattformen und Apps wie getasugar
								an. Hier kannst du gezielt nach spannenden Schweizer Gentlemen
								und Sugardaddys suchen.
							</p>
						</Grid>
					</Grid>
				</div>
			</div>

			<Footer />
		</div>
	);
}
